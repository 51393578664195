<template>
 <base-main-container>
    <v-overlay :value="isLoading">
        <v-progress-circular indeterminate size="68">
          AI is preparing your resource
        </v-progress-circular>
      </v-overlay>

       <template v-slot:centered>
      <v-card class="my-card rounded-lg" elevation="0">
        <v-card-title>Teaching Resources 
        
        </v-card-title>
        <v-card-text>
     
          <v-text-field v-model="topic"
            class="ml-2"
            label="Topic/Subject"
            outlined
          />
          <v-select v-model="grade"
            class=" ml-2"
            append-icon="mdi-filter"
            :items="[
              'K',
              '1',
              '2',
              '3',
              '4',
              '5',
              '6',
              '7',
              '8',
              '9',
              '10',
              '11',
              '12',
            ]"
            label="Suitable for Grade level"
            outlined
            clearable
          />
          <v-select v-model="learningStandards"
            class=" ml-2"
            :items="states"
            label="(Optional) Learning Standards"
            outlined
            clearable
          ></v-select>
          <v-text-field v-model="objectives"
            class="ml-2"
            label="(Optional) Specific learning goals (students be able to...)"
            outlined
          />
           <v-text-field v-model="priorknowledge"
            class="ml-2"
            label="(Optional) Students' existing knowledge about the topic"
            outlined
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
 <v-btn class="mr-2" outlined link to="newresource">
              <v-icon class="mr-2"> mdi-backspace</v-icon> Cancel
            </v-btn>
          <v-btn
            :disabled="!((what && topic && grade))
            "
            color="#212121"
            @click="sendRequest()"
          >
            <h3
              v-if="
               !((what && topic && grade))
              "
            >
              Generate please
            </h3>
            <h3 v-else style="color: white">Generate please</h3>
          </v-btn>

          <v-spacer />
        </v-card-actions>
      </v-card>
       </template>
   <v-snackbar
          v-model="showSnackbar"
          :timeout="6000"
           color="warning"
          top
          
        >
          {{ snackbarMessege }}
        </v-snackbar>
  </base-main-container>
</template>

<script>

import { getFunctions, httpsCallable } from "firebase/functions";

import { firebaseAuth } from "../../firebase";
import { db } from "../../firebase";

export default {
 
  data() {
    return {
      what: "Lesson Plan",
      topic: null,
      grade: null,
      isLoading: false,
      learningStandards: null,
      objectives:null,
      priorknowledge:null,
      resources:[],
      showSnackbar:false,
      snackbarMessege:"",
      states: [
        "Common Core",
        "Alabama",
        "Alaska",
        "Arizona",
        "Arkansas",
        "California",
        "Colorado",
        "Connecticut",
        "Delaware",
        "District of Columbia",
        "Florida",
        "Georgia",
        "Guam",
        "Hawaii",
        "Idaho",
        "Illinois",
        "Indiana",
        "Iowa",
        "Kansas",
        "Kentucky",
        "Louisiana",
        "Maine",
        "Maryland",
        "Massachusetts",
        "Michigan",
        "Minnesota",
        "Mississippi",
        "Missouri",
        "Montana",
        "Nebraska",
        "Nevada",
        "New Hampshire",
        "New Jersey",
        "New Mexico",
        "New York",
        "North Carolina",
        "North Dakota",
        "Ohio",
        "Oklahoma",
        "Oregon",
        "Pennsylvania",
        "Puerto Rico",
        "Rhode Island",
        "South Carolina",
        "South Dakota",
        "Tennessee",
        "Texas",
        "Utah",
        "Vermont",
        "Virgin Island",
        "Virginia",
        "Washington",
        "West Virginia",
        "Wisconsin",
        "Wyoming",
      ],
    };
  },
  // computed:{
  //  premium(){
  //     return this.$store.state.subscription ? this.$store.state.subscription.status =='active' 
  //     || this.$store.state.subscription.status == 'active school'
  //      || this.$store.state.subscription.status == 'trialing'
  //     : false
  //   }
  // },

  methods: {
  
    async sendRequest() {
      this.isLoading = true;
     let priorknowledge= this.priorknowledge !=null ? this.priorknowledge : "new topic"
     let objectives = this.objectives !=null ? this.objectives : "I dont have specific learning goals"
      let standards = this.learningStandards !=null ? this.learningStandards :"no specific Standards"
     let lessonplanpromt = [
  { "role": "system", "content": "You are an examplar teacher and you are able to write great lesson plans" },
  { "role": "user", "content": "I am a lazy teacher and I need a high quality lesson plan, can you write me one please" },
  { "role": "assistant", "content": "I will be glad to help you, please tell me what topic you want to teach and the grade level of your students" },
  { "role": "user", "content": "I will be teaching"+this.topic+" and my students are at grade "+this.grade },
  { "role": "assistant", "content": "Thank you for providing me this info. Now can you tell me if your students have existing knowledge about the topic or if this in an entirely new topic" },
  { "role": "user", "content": priorknowledge },
  { "role": "assistant", "content": "Thank you for providing me this info. Now can you tell me the learning goal for the lesson, what would you like students to understand or be able to do after the lesson" },
  { "role": "user", "content": objectives },
  { "role": "assistant", "content": "Thank you for providing me this info. Now can you tell me if you need to prepare this lesson based on specific learning standards" },
  { "role": "user", "content": standards },
  { "role": "assistant", "content": "Thank you for providing this info." },
  { "role": "user", "content": "Given all of this information, create a customized detailed lesson plan that includes a variety of teaching techniques and modalities including checking for understanding "},

 // { "role": "user", "content": "Given all of this information, create a customized lesson plan that includes a variety of teaching techniques and modalities including direct instruction, checking for understanding (including gathering evidence of understanding from a wide sampling of students), discussion, an engaging in-class activity, and an assignment with a few questions. "},
]


      let uid = firebaseAuth.currentUser.uid;
let newresource ={
    what: this.what,
        topic: this.topic,
        grade: this.grade,
        lastsaved: Date.now(), 
        promt: lessonplanpromt,
        uid:uid    
}
try {
          const userRef = db.collection("customers").doc(uid);
          const subcollectionRef = userRef.collection("saved");

          const docRef = await subcollectionRef.add(newresource)
          //console.log(docRef.id);
          newresource.id=docRef.id
        } catch (error) {
          console.error("Error adding document:", error);
        }

const functions = getFunctions();

      try { 
      const functionRef = httpsCallable(
        functions,
        "callopenai"
      );
      functionRef(newresource);
       
        this.isLoading = false;
        this.saveDialog = false;
        this.$router.push("/mydocs");

}catch (error) {
      this.isLoading = false;
      this.showSnackbar = true
      this.snackbarMessege = "The system is overloaded, please try again" 
}

      
    },
    
    clearSelections() {
      (this.topic = null),
        (this.grade = null),
        (this.objectives = null),
        (this.priorknowledge = null),
        (this.learningStandards = null);
    },
     
  },
  
};
</script>

<style scoped>

    .centered {
        border-radius: 15px;  
        background-color:#f3f6fc; 
        display: flex;
        justify-content: center;
        max-width: 1200px;
        height: 90vh; /* Adjust this value to match your desired height */
       
    }
    @media (min-width: 600px) {
  .my-card {
  
    width: 300px;
  }
}
@media (min-width: 960px) {
  .my-card {
   
    width: 500px;
  }
}
@media (min-width: 1264px) {
  .my-card {
   
    width: 800px;
    
  }
}
    .container.fill-height {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
}


</style>




