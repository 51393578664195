var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-main-container',{scopedSlots:_vm._u([{key:"centered",fn:function(){return [_c('v-card',{staticClass:"my-card rounded-lg",attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v("Teaching Resources ")]),_c('v-card-text',[_c('v-text-field',{staticClass:"ml-2",attrs:{"label":"Topic/Subject","outlined":""},model:{value:(_vm.topic),callback:function ($$v) {_vm.topic=$$v},expression:"topic"}}),_c('v-select',{staticClass:"ml-2",attrs:{"append-icon":"mdi-filter","items":[
             'K',
             '1',
             '2',
             '3',
             '4',
             '5',
             '6',
             '7',
             '8',
             '9',
             '10',
             '11',
             '12',
           ],"label":"Suitable for Grade level","outlined":"","clearable":""},model:{value:(_vm.grade),callback:function ($$v) {_vm.grade=$$v},expression:"grade"}}),_c('v-select',{staticClass:"ml-2",attrs:{"items":_vm.states,"label":"(Optional) Learning Standards","outlined":"","clearable":""},model:{value:(_vm.learningStandards),callback:function ($$v) {_vm.learningStandards=$$v},expression:"learningStandards"}}),_c('v-text-field',{staticClass:"ml-2",attrs:{"label":"(Optional) Specific learning goals (students be able to...)","outlined":""},model:{value:(_vm.objectives),callback:function ($$v) {_vm.objectives=$$v},expression:"objectives"}}),_c('v-text-field',{staticClass:"ml-2",attrs:{"label":"(Optional) Students' existing knowledge about the topic","outlined":""},model:{value:(_vm.priorknowledge),callback:function ($$v) {_vm.priorknowledge=$$v},expression:"priorknowledge"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"outlined":"","link":"","to":"newresource"}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-backspace")]),_vm._v(" Cancel ")],1),_c('v-btn',{attrs:{"disabled":!((_vm.what && _vm.topic && _vm.grade)),"color":"#212121"},on:{"click":function($event){return _vm.sendRequest()}}},[(
              !((_vm.what && _vm.topic && _vm.grade))
             )?_c('h3',[_vm._v(" Generate please ")]):_c('h3',{staticStyle:{"color":"white"}},[_vm._v("Generate please")])]),_c('v-spacer')],1)],1)]},proxy:true}])},[_c('v-overlay',{attrs:{"value":_vm.isLoading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"68"}},[_vm._v(" AI is preparing your resource ")])],1),_c('v-snackbar',{attrs:{"timeout":6000,"color":"warning","top":""},model:{value:(_vm.showSnackbar),callback:function ($$v) {_vm.showSnackbar=$$v},expression:"showSnackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarMessege)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }